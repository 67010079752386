import layout from '@/layout'

export default {
  path: '/wxuser',
  component: layout,
  redirect: '/wxuser/manage',
  name: 'wxuserManage',
  meta: {
    title: 'user',
    icon: 'personnel'
  },
  children: [
    {
      path: '/wxuser/manage',
      component: () =>
        import(/* webpackChunkName: "user-manage" */ '@/views/wxuser/index'),
      meta: {
        title: 'user',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/wxuser/xiaohonghualist',
      component: () =>
        import(
          /* webpackChunkName: "user-manage" */ '@/views/wxuser/xiaohonghualist'
        ),
      meta: {
        title: 'xiaohonghualist',
        icon: 'personnel-manage'
      }
    },
    {
      path: '/wxuser/tixianlist',
      component: () =>
        import(
          /* webpackChunkName: "user-manage" */ '@/views/wxuser/tixianlist'
        ),
      meta: {
        title: 'tixianlist',
        icon: 'personnel-manage'
      }
    }
  ]
}
