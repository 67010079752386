import layout from '@/layout'

export default {
  path: '/sys-setting',
  component: layout,
  redirect: '/sys-setting/index',
  name: 'sysSettingManage',
  meta: { title: 'sysSettingManage', icon: 'community' },
  children: [
    {
      path: '/sys-setting/index',
      component: () =>
        import(
          /* webpackChunkName: "sysSettingManage" */ '@/views/sys-setting/index'
        ),
      meta: {
        title: 'sysSettingManage',
        icon: 'community'
      }
    }
  ]
}
