import layout from '@/layout'

export default {
  path: '/goods',
  component: layout,
  redirect: '/goods/product',
  name: 'goodsManage',
  meta: { title: 'myProduct', icon: 'goods_light' },
  children: [
    {
      path: '/goods/category',
      component: () =>
        import(/* webpackChunkName: "category" */ '@/views/goods/category'),
      meta: {
        title: 'productCategory',
        icon: 'category'
      }
    },
    {
      path: '/goods/product',
      component: () =>
        import(/* webpackChunkName: "product" */ '@/views/goods/product'),
      meta: {
        title: 'productList',
        icon: 'goods_light'
      }
    },

    {
      path: '/goods/kaquan',
      component: () =>
        import(/* webpackChunkName: "kaquan" */ '@/views/goods/kaquan'),
      meta: {
        title: 'kaquan',
        icon: 'goods_light'
      }
    },
    {
      path: '/goods/log',
      component: () =>
        import(/* webpackChunkName: "product" */ '@/views/goods/log'),
      meta: {
        title: 'buyLog',
        icon: 'buyLog'
      }
    }
  ]
}
