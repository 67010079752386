import request from '@/utils/request'

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/sys/login',
    method: 'POST',
    data
  })
}
/**
 * 刷新token
 */
export const refreshToken = (token) => {
  return request({
    url: '/authorizations/current',
    method: 'PUT'
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/sys/profile'
  })
}
// 系统设置
export const getSetting = () => {
  return request({
    url: '/sys/setting'
  })
}
// 设置修改
export const updateSetting = (sys_id, data) => {
  return request({
    url: `/sys/setting/${sys_id}/update`,
    method: 'POST',
    data
  })
}
/**
 * 获取系统设置
 */
export const getSysInfo = (name) => {
  return request({
    url: 'sys/value',
    params: {
      name
    }
  })
}
