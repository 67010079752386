import layout from '@/layout'

export default {
  path: '/poi',
  component: layout,
  redirect: '/poi/index',
  name: 'poiManage',
  meta: { title: 'poi', icon: 'poi' },
  children: [
    {
      path: '/poi/category',
      component: () =>
        import(/* webpackChunkName: "category" */ '@/views/poi/category'),
      meta: {
        title: 'poiCategory',
        icon: 'category'
      }
    },
    {
      path: '/poi/index',
      component: () =>
        import(/* webpackChunkName: "poi" */ '@/views/poi/index'),
      meta: {
        title: 'poi',
        icon: 'poi'
      }
    }
  ]
}
