import layout from '@/layout'

export default {
  path: '/community',
  component: layout,
  redirect: '/community/index',
  name: 'communityManage',
  meta: { title: 'communityManage', icon: 'community' },
  children: [
    {
      path: '/community/category',
      component: () =>
        import(
          /* webpackChunkName: "categorys" */ '@/views/community/categorys'
        ),
      meta: {
        title: 'communityCategory',
        icon: 'community'
      }
    },
    {
      path: '/community/index',
      component: () =>
        import(/* webpackChunkName: "index" */ '@/views/community/index'),
      meta: {
        title: 'communityManage',
        icon: 'community'
      }
    }
  ]
}
