import layout from '@/layout'

export default {
  path: '/advertisers',
  component: layout,
  redirect: '/advertisers/index',
  name: 'advertisersManage',
  meta: { title: 'advertisers', icon: 'adv' },
  children: [
    {
      path: '/advertisers/index',
      component: () =>
        import(/* webpackChunkName: "fuwus" */ '@/views/adv/fuwus'),
      meta: {
        title: 'advertisers',
        icon: 'adv'
      }
    }
  ]
}
