import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
import UserManageRouter from './modules/UserManage'
import RoleListRouter from './modules/RoleList'
import PermissionListRouter from './modules/PermissionList'
// import ArticleRouter from './modules/Article'
// import ArticleCreaterRouter from './modules/ArticleCreate'
import store from '@/store'
import GoodsManage from './modules/GoodsManage'
import Poi from './modules/Poi'
import Adv from './modules/Adv'
import Community from './modules/CommunityManage'
import Advertisers from './modules/Advertisers'
import SysSettingManage from './modules/SysSettingManage'
import Banner from './modules/Banner'
import WxUser from './modules/WxUser'

/**
 * 私有路由表
 */
export const privateRoutes = [
  UserManageRouter,
  RoleListRouter,
  PermissionListRouter,
  // ArticleRouter,
  // ArticleCreaterRouter,
  GoodsManage,
  Poi,
  Banner,
  Adv,
  Community,
  Advertisers,
  SysSettingManage,
  WxUser
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index')
  },
  {
    path: '/',
    component: layout,
    redirect: '/profile',
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/index'),
        meta: {
          title: 'profile',
          icon: 'personnel'
        }
      },
      // {
      //   path: '/chart',
      //   name: 'chart',
      //   component: () =>
      //     import(/* webpackChunkName: "chart" */ '@/views/chart/index'),
      //   meta: {
      //     title: 'chart',
      //     icon: 'chart'
      //   }
      // },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach((menu) => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

export default router
