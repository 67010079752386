import layout from '@/layout'

export default {
  path: '/banner',
  component: layout,
  redirect: '/banner/index',
  name: 'bannerManage',
  meta: { title: 'banner', icon: 'adv' },
  children: [
    {
      path: '/banner/index',
      component: () =>
        import(/* webpackChunkName: "banner" */ '@/views/banner/index'),
      meta: {
        title: 'lunbotu',
        icon: 'adv'
      }
    },
    {
      path: '/banner2/index',
      component: () =>
        import(/* webpackChunkName: "banner2" */ '@/views/banner2/index'),
      meta: {
        title: 'guanggao',
        icon: 'adv'
      }
    }
  ]
}
